.gameCategoryList {
    /*FireFox*/
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
}

.gameCategoryList::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
}