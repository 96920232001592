html,
body {
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

.full-height {
  height: 100%;
  width: 100%;
}

.column {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.column::-webkit-scrollbar {
  display: none;
}

body>#root>div {
  height: 100%;
  width: 100%;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10000;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.spinnerMain {
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  display: table;
}

.spinnerContent {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}