.popupContainer {
    /*FireFox*/
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.popupContainer::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
}