.hideScrollBar {
    /*FireFox*/
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: none;
}

.hideScrollBar::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
    width: 0;  /* Remove scrollbar space */
    height: 0;
    background: transparent;  /* Optional: just make scrollbar invisible */
}